import logo from "./logo.svg";
import "./App.css";
import mylogo from "./assets/dt-logo.png"; // Importă logo-ul tău
import MeLaptop from "./assets/me-laptop.png";
import Devmenu from "./components/Devmenu";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo w-[140px] mb-0" alt="logo" />
        <img src={MeLaptop} className="h-auto mr-1" />
        <div className="w-[820px] mt-0">
          <div>
            <h1 className="font-semibold text-green-200 mb-4">
              Dinu Tonica - Web Designer and Developer A1
            </h1>
            <p className="text-white/50 flex items-center text-lg">
              <img
                src={logo}
                alt="Dinu Tonica logo"
                className="w-12 h-auto mr-1"
              />
              Salutare, numele meu este Tony si lucrez ca si Freelancer de peste
              9 ani in domeniu Web design si Developemnet
            </p>
          </div>
        </div>
        <Contact />
      </header>
    </div>
  );
}

export default App;
