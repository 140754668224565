import { useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_7tcigni", "template_0lru3cf", form.current, {
        publicKey: "TS6NPtmml1rhpIid-",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
    e.target.reset();
    alert("Formularul a fost trimis cu succes!");
  };

  return (
    <section>
      <h1>Contact Me today</h1>
      <form ref={form} onSubmit={sendEmail}>
        <label>Numele dvs.</label>
        <input
          type="text"
          name="user_name"
          className="bg-gray-700 border border-white/50 rounded-lg mx-2 my-2 w-[100%]"
        />
        <label>Adresa de Email</label>
        <input
          type="email"
          name="user_email"
          className="bg-gray-700 border border-white/50 rounded-lg mx-2 my-2 w-[100%]"
        />
        <label>Mesajul dvs.</label>
        <textarea
          name="message"
          className="bg-gray-700 border border-white/50 rounded-lg mx-2 my-2 w-[100%]"
        />
        <button
          type="submit"
          className="g-gray-700 border border-white/50 rounded-lg mx-2 my-2 w-[100%]"
        >
          Trimite Mesajul
        </button>
      </form>
    </section>
  );
};

export default Contact;
